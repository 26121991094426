@charset "utf-8";

/*=================================
  common
=================================*/
body {
  @extend %font;
  position: relative;
  word-break: break-word;
}
.float-l {
  float: left;
}
.float-r {
  float: right;
}
.note {
  font-size: 1.5rem;
  display: block;
}
.is-sp-only {
  display: none!important;
}
.is-text-center {
  text-align: center;
}
.is-text-right {
  text-align: right;
}
.is-text-note {
  font-size: 1.2rem;
  padding-left: 1em;
  text-indent: -1em;
  display: block;
}
.inner-wrap {
  max-width: $base-width;
  margin: 0 auto;
}
.cf:after {
  content:" ";
  display:block;
  clear:both;
}
/*共通レイアウト*/
.mainWrap {
  &.under {
    background: #F3F3F3;
    .secWrap {
      padding: 58px 1% 90px;
      background: #fff;
      &__inner {
        max-width: $base-width;
        margin: 0 auto;
      }
    }
  }
}
.layout--2col {
  max-width: calc(#{$base-width} + 2%);
  margin: 0 auto;
  padding: 60px 1% 90px;
  display: flex;
  align-items: flex-start;
  &__cont {
    width: calc(100% - (320px + vw(60)));
  }
  &.cont--r {
    flex-direction: row-reverse;
    .layout--2col__cont {
      margin-left: vw(60);
    }
  }
  &.cont--l {
    .layout--2col__cont {
      margin-right: vw(60);
    }
  }
}

  /*=================================
    wp-image
  =================================*/
  img.aligncenter,img.alignnone,img.alignleft,img.alignright { margin-left: auto; margin-right: auto; margin-bottom: 1em; display: block; }

  @media screen and (min-width: 768px) {
  img.aligncenter,img.alignnone { margin-left: auto; margin-right: auto; display: block; }
  img.alignleft { float: left; margin: 0 1em 1.5em 0;}
  img.alignright { float: right; margin: 0 0 1.5em 1em;}
  }
    /*=================================
      btn
    =================================*/
  .btn_green a { /*display: block;*/ background: #1a982a; border-radius: 4px; box-shadow: 0 3px 0 #14701f; color: #fff; display: block; width: 100%; height: 70px; padding-top: 25px; font-size: 20px; font-weight: 700; text-align: center; text-decoration: none; line-height: 1; }
  .btn_green a i { margin-right: 7px; }
  .box_top { margin-top: 20px; }
  
  @media screen and (min-width: 768px) {
  .btn_green a { box-shadow: 0 4px 0 #14701f; width: 380px; height: 90px; padding-top: 35px; font-size: 24px; }
  .btn_green a:hover { box-shadow: none !important; transform: translate3d(0, 4px, 0); }
  }
    /*=================================
      marker
    =================================*/
  .marker { font-size: 112%; color: #f62f2f; font-weight: 700; background: linear-gradient(transparent 50%, #fff099 50%); }
  

@media screen and (max-width: 1023px){
  .layout--2col {
    padding: per(40) per(17);;
    display: block;
    &__cont {
      width: 100%;
    }
    &.cont--r {
      .layout--2col__cont {
        margin: 0;
      }
    }
    &.cont--l {
      .layout--2col__cont {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: $display-width-s){
  /*=================================
    common
  =================================*/
  html,body {
    min-width: 320px;
  }
  .is-sp-only {
    display: inline-block!important;
  }
  .is-pc-only {
    display: none!important;
  }
  .note {
    font-size: 1.1rem;
  }
  /*共通レイアウト*/
  .mainWrap {
    &.under {
      .secWrap {
        padding: 30px per(17) 40px;
      }
    }
  }
}